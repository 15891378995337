<template>
  <div class="buyLogin">
    <div class="cell">
      <div class="box">
        <div style="font-size: 20px" v-if="$store.state.buyLoginType == 1">
          <div>注册</div>
          <div>
            <el-input
              style="main-width: 315px; margin-top: 20px"
              v-model.trim="phone"
              placeholder="手机号码"
              @input="handleInput"
              type="number"
            ></el-input>
            <div
              v-if="message && message != '必填电话'"
              style="color: #ee4d2d; font-size: 12px; margin-top: 4px"
            >
              {{ message }}
            </div>
          </div>
          <div>
            <el-button
              @click="next(1)"
              style="min-width: 337px; margin-top: 30px"
              type="primary"
              :disabled="message != false"
              >NEXT</el-button
            >
          </div>
          <div style="text-align: center; font-size: 12px; margin-top: 30px">
            若要继续注册，请先阅读并同意Shopee的
            <span class="shopee-red" style="cursor: pointer">服务条款</span>
            <br />
            与<span class="shopee-red" style="cursor: pointer">
              隐私权政策 </span
            >。
          </div>
          <div
            style="
              font-size: 14px;
              color: #00000042;
              margin-top: 30px;
              text-align: center;
            "
          >
            已有账号？<span
              class="shopee-red"
              style="cursor: pointer"
              @click="handleClick"
              >登入</span
            >
          </div>
        </div>
        <div style="font-size: 20px" v-else>
          <div style="margin-top: 10px" v-if="loginType != 2">
            <el-tabs
              style="border-bottom: none"
              v-model="loginType"
              @tab-click="handleLoginType"
            >
              <el-tab-pane label="普通账号" name="1"></el-tab-pane>
              <el-tab-pane label="优优云平台账号" name="3"></el-tab-pane>
            </el-tabs>
          </div>
          <div v-else>登入</div>
          <div v-if="loginType == 1 || loginType == 3">
            <div>
              <el-input
                style="main-width: 315px; margin-top: 20px"
                v-model.trim="acc"
                onfocus="this.removeAttribute('readonly');" readonly
                :placeholder="
                  loginType == 1 ? '电话号码/用户名称' : '优优云平台账号'
                "
              ></el-input>
            </div>
            <div>
              <el-input
                style="main-width: 315px; margin-top: 20px"
                v-model.trim="password"
                placeholder="密码"
                show-password
              ></el-input>
            </div>
            <div>
              <el-button
                @click="submit"
                style="min-width: 337px; margin: 30px 0 10px"
                type="primary"
                :disabled="acc == '' || password == ''"
                >登入</el-button
              >
            </div>
            <div>
              <!-- <el-button
                @click="authLogin"
                style="min-width: 337px; margin: 10px 0 10px"
                plain
                >授权登录</el-button
              > -->
            </div>
          </div>
          <div v-if="loginType == 2">
            <div v-if="login2Seep == 1">
              <el-input
                style="main-width: 315px; margin-top: 20px"
                v-model.trim="phone"
                placeholder="手机号码"
                type="number"
                @input="handleInput"
              ></el-input>
              <div>
                <el-button
                  @click="submit"
                  style="min-width: 337px; margin: 30px 0"
                  type="primary"
                  :disabled="message != false"
                  >NEXT</el-button
                >
              </div>
            </div>
            <div v-if="login2Seep == 2">
              <vue-sms-check-code
                title=" "
                :code-num="6"
                :is-error="isError"
                error-color="#ee4d2d"
                @finish="getSmsCodes"
                style="width: 300px; font-size: 16px"
              />
              <div style="text-align: center">
                <div style="font-size: 14px; color: green; margin-bottom: 10px">
                  验证码：{{ smsCodeText }}
                </div>
                <div style="color: #b0b0b0; font-size: 14px">
                  <span v-if="num != 60">请稍等{{ num }}秒后重新发送</span>
                  <span
                    v-else
                    style="color: #ee4d2d; text-decoration: underline"
                    @click="getSmsCodeF"
                    >重新发送</span
                  >
                </div>
              </div>
              <div>
                <el-button
                  @click="submit"
                  style="min-width: 337px; margin: 30px 0"
                  type="primary"
                  :disabled="smsCode == ''"
                  >NEXT</el-button
                >
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              margin-top: 6px;
            "
          >
            <div style="color: #05a; min-weight: 10px; cursor: pointer">
              <span v-if="loginType == 1" @click="toPush">忘记密码</span>
            </div>
            <div
              v-if="loginType == 1 || loginType == 2"
              style="color: #05a; cursor: pointer"
              @click="loginTypeqh"
            >
              使用{{ loginType == 1 ? "电话号码" : "密码" }}登入
            </div>
          </div>

          <div
            v-if="loginType == 1 || loginType == 2"
            style="
              font-size: 14px;
              color: #00000042;
              margin-top: 30px;
              text-align: center;
            "
          >
            还没注册？<span
              class="shopee-red"
              style="cursor: pointer"
              @click="handleClick"
              >注册</span
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="验证"
      :visible.sync="dialogVisible"
      width="350px"
      center
      :close-on-click-modal="false"
      style="padding-top: 100px"
    >
      <slide-verify
        :l="42"
        :r="10"
        :w="310"
        :h="155"
        :show="false"
        :imgs="puzzleImgList"
        @success="onSuccess"
        @fail="onFail"
        ref="slideblock"
        :slider-text="text"
      ></slide-verify>
      <div
        @click="onRefresh"
        style="
          text-align: center;
          margin-top: 20px;
          color: #0055aa;
          cursor: pointer;
          font-size: 12px;
        "
      >
        点击以刷新图片
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { setBAuth, setSchool } from "@/utils/auth.js";
import { buyLogin } from "@/api/buy.js";
import { validation } from "@/utils/formCheck.js";
import { getSmsCode, findUserInfo } from "@/api/common.js";
export default {
  data() {
    return {
      phone: "",
      password: "",
      message: "必填电话",
      dialogVisible: false,
      text: "滑动横条以完成拼图验证",
      imgRZ: false,
      acc: "",
      loginType: "1", //1-密码  2-电话号码
      login2Seep: 1, //电话登录步骤
      smsCode: "",
      smsCodeText: "获取中···",
      num: 59,
      time: null,
      isError: false,
      puzzleImgList: [
        require("@/assets/img/charm/1.jpg"),
        require("@/assets/img/charm/2.jpg"),
        require("@/assets/img/charm/3.jpg"),
      ],
      activeName: "1",
    };
  },
  created() {
    // if (this.$route.query.c) {
    //   login({
    //     code: this.$route.query.c,
    //   }).then((res) => {
    //     if (res.code == 1) {
    //       setBAuth(res.data.token);
    //       setSchool(res.data.school);
    //       this.$router.replace("buyHome");
    //     }
    //   });
    // }
  },
  mounted() {
    this.keyDown();
  },
  beforeDestroy() {
    document.onkeydown = null;
  },
  methods: {
    handleClick() {
      this.$store.state.buyLoginType =
        this.$store.state.buyLoginType == 1 ? 2 : 1;
      this.phone = "";
      this.message = "";
      this.acc = "";
      this.loginType = "1";
      this.password = "";
      this.login2Seep = 1;
    },
    handleInput() {
      this.message = validation(this.phone, "phone");

    },
    next(type) {
      if (type) {
        findUserInfo({
          account: this.phone,
        }).then((res) => {
          if (res.code == 1) {
            this.$nextTick(() => {
              this.onRefresh();
            });
            this.dialogVisible = true;
          }
        });
      } else {
        this.$nextTick(() => {
          this.onRefresh();
        });
        this.dialogVisible = true;
      }
    },
    onSuccess() {
      console.log("成功");
      let _this = this;
      this.imgRZ = true;
      setTimeout(function () {
        _this.dialogVisible = false;
        // 图形验证登录
        if (_this.$store.state.buyLoginType == 2) {
          if (_this.loginType == 1 || _this.loginType == 3) {
            _this.login();
          }
          if (_this.loginType == 2) {
            _this.login2Seep = 2;
            _this.getSmsCodeF();
          }
        }
        // 图形验证注册
        if (_this.$store.state.buyLoginType == 1) {
          sessionStorage.SIGNUPPHONE = _this.phone;
          _this.$router.push("buySignup");
        }
      }, 1000);
    },
    onFail() {
      console.log("失败");
    },
    onRefresh() {
      console.log("刷新了");
      if (this.$refs.slideblock) {
        this.$refs.slideblock.reset();
      }
    },
    submit() {
      // 密码登录图形验证
      if (this.loginType == 1 || this.loginType == 3) {
        this.next();
        return false;
      }
      // 电话号码登录图形验证
      if (this.loginType == 2 && this.login2Seep == 1) {
        this.next();
        return false;
      }

      // 电话号码登录获取验证码
      if (this.loginType == 2 && this.login2Seep == 2) {
        this.login();
      }
    },
    login() {
      if (this.loginType == 1 || this.loginType == 3) {
        if (!this.acc || !this.password) {
          this.$message.error("账号密码不能为空！");
          return false;
        }
      }

      let _this = this;
      let acc = "";
      acc = this.loginType == 1 || this.loginType == 3 ? this.acc : this.phone;
      buyLogin({
        account: acc,
        password: this.password,
        code: this.smsCode,
        type: this.loginType,
      }).then((res) => {
        if (res.code == 1) {
          localStorage.BUTLOGINTYPE = res.data.type;
          this.$message.success("登陆成功");
          setSchool(res.data.school);
          setBAuth(res.data.token);
          _this.$router.replace("buyHome");
        }
      });
    },
    loginTypeqh() {
      this.loginType = this.loginType == 1 ? "2" : "1";
      this.acc = "";
      this.password = "";
      this.phone = "";
    },
    getSmsCodeF() {
      let _this = this;
      this.smsCodeText = "获取中···";
      this.isError = true;
      getSmsCode({
        mobile: this.phone,
      }).then((res) => {
        if (res.code == 1) {
          this.time = setInterval(function () {
            _this.num--;
            _this.smsCodeText = res.data.code;
            if (_this.num == 0) {
              clearInterval(_this.time);
              _this.num = 60;
            }
          }, 1000);
        }
      });
    },
    getSmsCodes(value) {
      let _this = this;
      this.isError = false;
      if (value !== this.smsCodeText) {
        setTimeout(() => {
          _this.isError = true;
          _this.$message.error("验证码错误");
        }, 500);
      } else {
        this.smsCode = this.smsCodeText;
      }
    },
    toPush() {
      this.$router.push("buyForget");
    },
    keyDown() {
      let _this = this;
      document.onkeydown = (e) => {
        if (e.keyCode == 13) {
          if (_this.acc && _this.password) {
            _this.submit();
          }
        }
      };
    },
    handleLoginType() {
      this.acc = "";
      this.password = "";
    },
  },
};
</script>
<style lang="less" >
.buyLogin {
  background: #ee4d2d;
  .el-tabs__nav-wrap::after {
    background: #fff;
  }
  .el-tabs__item {
    font-weight: bold;
    font-size: 18px;
  }
  .cell {
    display: flex;
    width: 1040px;
    height: 600px;
    margin: 0 auto;
    background-image: url("../../assets/img/buy/buyLoginBj.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .box {
      width: 398px;
      // height: 428px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4);
      padding: 20px 30px;
      .el-input__inner[type="number"]::-webkit-inner-spin-button,
      .el-input__inner[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
</style>